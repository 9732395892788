import React from "react"
import { PageProps } from "gatsby"
import styled from "styled-components"

import SEO from "../../components/seo"

import { GridContainer } from "../../components/grid"
import { SecondaryHero } from "../../components/heros"
import { MainCard } from "../../components/cards"
import { OnlineAcademyCTA } from "../../components/ctas"
import { BaseContainer } from "../../components/containers"
import { H2, H3 } from "../../components/text"

const ServicesCardsContainer = styled(BaseContainer)`
  grid-column: 1 / span 12;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 10;
  }
`

const ServicesCards: React.FC = () => (
  <ServicesCardsContainer>
    <MainCard
      to="/diensten/organisatieontwikkeling/"
      title="Organisatieontwikkeling"
    >
      Het groeipotentieel van een organisatie benutten door zowel structuur, de
      mens in de organisatie en de cultuur te ontwikkelen, om zo de interne en
      externe uitdagingen aan te kunnen.
    </MainCard>
    <MainCard to="/diensten/teamontwikkeling/" title="Teamontwikkeling">
      Kwaliteit, productiviteit en plezier in teams vergroten door betere
      samenwerking en inzicht in elkaars talenten.
    </MainCard>
    <MainCard to="/diensten/leiderschap/" title="Leiderschap">
      Leiderschap begint met het leiden van jezelf. Pas dan kun je werkelijk
      leidinggeven aan een team en organisatie.
    </MainCard>
    <MainCard
      to="/diensten/diversiteit-en-inclusie/"
      title="Diversiteit &amp; Inclusie"
    >
      Een inclusieve organisatie is een organisatie waar je jezelf kunt zijn en
      waar je erbij hoort. Zo’n organisatie zal beter functioneren omdat hier de
      ruimte is voor verschillende inzichten en talenten.
    </MainCard>
  </ServicesCardsContainer>
)

const MethodContainer = styled(BaseContainer)`
  background: ${({ theme }) => theme.colors.beukelaarGreenDark};
  color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 4rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: -16rem;
    padding: 16rem 0 8rem 0;
  }
`

interface MethodCardProps {
  title: string
  subtitle: string
}

const MethodCardTitle = styled(H3)`
  margin-bottom: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 2rem;
  }
`

const MethodCard: React.FC<MethodCardProps> = ({ title, subtitle }) => (
  <div>
    <MethodCardTitle>{title}</MethodCardTitle>
    <p>{subtitle}</p>
  </div>
)

const MethodTitle = styled(H2)`
  grid-column: 2 / span 10;
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 2 / span 3;
    margin-bottom: 0;
  }
`

const MethodCardsContainer = styled.div`
  grid-column: 2 / span 10;
  display: inline-grid;
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 7 / span 4;
    gap: 4rem;
  }
`

const OurMethod: React.FC = () => (
  <MethodContainer>
    <MethodTitle>Onze werkwijze</MethodTitle>
    <MethodCardsContainer>
      <MethodCard
        title="Hoofd - Besturing en strategie"
        subtitle="Ons hoofd staat voor denken, rationaliteit, verstandelijk beredeneren, berekenen, beschouwen, begrijpen en weten. Ons verstand wil onderbouwing zien, bewijzen en logica."
      />
      <MethodCard
        title="Hart - Missie, visie &amp; cultuur"
        subtitle="Ons hart staat voor emotie, gevoelens maar ook passie en bezieling, beleving, vertrouwen, betrokkenheid en ervaring. Ook onze motivatie en innerlijke drijfveren komen voort uit ons hart of uit ons gevoel."
      />
      <MethodCard
        title="Handen - Werkverdeling en HR"
        subtitle="Onze handen staan voor praktisch, aarden, concretiseren, uitvoeren, implementeren en in de praktijk toepassen."
      />
      <MethodCard
        title="Flow - Gezonde organisatie"
        subtitle="Flow doet zich voor wanneer Hoofd, Hart en Handen op een lijn samenwerken."
      />
    </MethodCardsContainer>
  </MethodContainer>
)

const ServicesPage: React.FC<PageProps> = () => (
  <>
    <SEO
      title="Diensten"
      description="Of het nu gaat om innovatief denken, het consolideren van een nieuwe strategie, het verbeteren van een team of om sterk leiderschap, we gebruiken altijd de lijn hoofd, hart en handen om tot een structurele verandering te komen."
    />
    <GridContainer>
      <SecondaryHero
        title="Diensten"
        subtitle="Trainen, opleiden en coachen zijn en blijven belangrijke elementen in de ontwikkeling van management en talent in een organisatie. Of het nu gaat om innovatief denken, het consolideren van een nieuwe strategie, het verbeteren van een team of om sterk leiderschap, we gebruiken altijd de lijn hoofd, hart en handen om tot een structurele verandering te komen."
      ></SecondaryHero>
      <ServicesCards />
      <OurMethod />
      <OnlineAcademyCTA />
    </GridContainer>
  </>
)

export default ServicesPage
